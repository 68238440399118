import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { UserDomainService } from '@app/domain/user-domain.service';
import { AuthStorageService, UserRole } from '@ess-front/shared';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OnboardingSurveyGuard implements CanActivate {
  private readonly MEMBERS_AREA_URL = '/members-area';
  private readonly MEMBERS_AREA_LOGIN_URL = '/members-area/login';
  private readonly ONBOARDING_SURVEY_URL = '/onboarding-survey';

  constructor(
    private authStorageService: AuthStorageService,
    private userDomainService: UserDomainService,
    private router: Router,
  ) {}

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    const isOnboardingRoute = activatedRouteSnapshot.data.isOnboardingRoute as boolean;
    const userRole = this.authStorageService.getUserRole();
    const userHasToDoOnboarding = this.hasToDoOnboarding(userRole);

    if (this.authStorageService.getToken()) {
      return this.userDomainService.getCurrentUser$().pipe(
        filter(user => !!user),
        switchMap(user => {
          if (this.shouldRedirectToMembersArea(isOnboardingRoute, user.isOnboardingDone, userHasToDoOnboarding)) {
            this.router.navigate([this.MEMBERS_AREA_URL]);
            return of(false);
          }
          if (this.shouldRedirectToOnboarding(isOnboardingRoute, user.isOnboardingDone, userHasToDoOnboarding)) {
            this.router.navigate([this.ONBOARDING_SURVEY_URL]);
            return of(false);
          }
          return of(true);
        }),
        take(1),
        catchError((err: unknown) => this.onError$(err)),
      );
    } else {
      this.router.navigate([this.MEMBERS_AREA_LOGIN_URL]);
      return of(false);
    }
  }

  private shouldRedirectToMembersArea(
    isOnboardingRoute: boolean,
    isOnboardingDone: boolean,
    userHasToDoOnboarding: boolean,
  ): boolean {
    return isOnboardingRoute && (isOnboardingDone || !userHasToDoOnboarding);
  }

  private shouldRedirectToOnboarding(
    isOnboardingRoute: boolean,
    isOnboardingDone: boolean,
    userHasToDoOnboarding: boolean,
  ): boolean {
    return !isOnboardingRoute && !isOnboardingDone && userHasToDoOnboarding;
  }

  private hasToDoOnboarding(userRole: UserRole): boolean {
    return userRole === UserRole.EssentialistMember || userRole === UserRole.LatamMember;
  }

  private onError$(error: unknown): Observable<boolean> {
    this.router.navigate([this.MEMBERS_AREA_LOGIN_URL]);
    throw error;
  }
}
