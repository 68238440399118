import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslationDomainService } from '@domain/translation-domain.service';

@Injectable({ providedIn: 'root' })
export class DefaultTranslationResolver implements Resolve<string> {
  private readonly DEFAULT_LANGUAGE = 'en';

  constructor(private translationDomainService: TranslationDomainService) {}

  resolve() {
    const userLanguage = this.translationDomainService.getUserLanguage();
    if (userLanguage) {
      this.activateLanguage(userLanguage);
      return userLanguage;
    }

    const browserLanguage = this.translationDomainService.getBrowserLanguage();
    if (browserLanguage && this.translationDomainService.isLanguageSupported(browserLanguage)) {
      this.activateLanguage(browserLanguage);
      return browserLanguage;
    }

    this.activateLanguage(this.DEFAULT_LANGUAGE);
    return this.DEFAULT_LANGUAGE;
  }

  private activateLanguage(language: string) {
    if (language !== this.translationDomainService.getActiveLanguage()) {
      this.translationDomainService.setActiveLanguage(language);
    }
  }
}
