import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
import { SupportedLanguage } from '@app/utils/constants/ess-date-config';

@Injectable({
  providedIn: 'root',
})
export class CustomDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    switch (this.locale) {
      case SupportedLanguage.En:
      case SupportedLanguage.Pt:
        return 0; // Sunday
      case SupportedLanguage.Es:
      default:
        return 1; // Monday
    }
  }
}
