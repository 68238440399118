import { Injectable } from '@angular/core';
import { Deferred } from '@app/utils/defer/deferred.util';
import { environment } from '@environment';
import { TravelDesigner } from '@shared/models/itinerary/travel-designer.model';

import { User } from '@shared/models/user/user.model';
import Talk from 'talkjs';

@Injectable({
  providedIn: 'root',
})
export class TalkJsService {
  private currentSessionDeferred = new Deferred<Talk.Session>();

  async initTalkJsSession(user: Talk.User, chatSignature: string) {
    await Talk.ready;
    const session = new Talk.Session({
      appId: environment.talkJsAppKey,
      me: user,
      signature: chatSignature,
    });
    this.currentSessionDeferred = new Deferred<Talk.Session>();
    this.currentSessionDeferred.resolve(session);
  }

  async getTalkJsSession(): Promise<Talk.Session> {
    return this.currentSessionDeferred.promise;
  }

  async createPopup(keepOpen = false): Promise<Talk.Popup> {
    const session = await this.currentSessionDeferred.promise;
    if (session.isAlive) {
      return session.createPopup({
        keepOpen: keepOpen,
        showChatHeader: true,
        showCloseInHeader: true,
      });
    }
  }

  async createTalkUser(applicationUser: User | TravelDesigner, userLanguage: string): Promise<Talk.User> {
    await Talk.ready;
    return new Talk.User({
      id: applicationUser.id,
      name: `${applicationUser.firstName} ${applicationUser.lastName}`,
      role: applicationUser.talkJsRole,
      photoUrl: applicationUser.avatar,
      locale: userLanguage,
    });
  }

  async getOrCreateConversation(tripHash: string, tripTitle: string): Promise<Talk.ConversationBuilder> {
    const session = await this.currentSessionDeferred.promise;
    if (session.isAlive) {
      const conversationId = environment.production ? tripHash : `staging__${tripHash}`;
      const conversationBuilder = session.getOrCreateConversation(conversationId);

      conversationBuilder.setAttributes({
        subject: tripTitle,
        custom: { type: 'member_booking' },
      });
      return conversationBuilder;
    }
    return null;
  }

  addPopupNotifications(unreadMessages: number) {
    const talkJsBubbleRef = document.getElementById('__talkjs_launcher');
    if (!talkJsBubbleRef) return;

    talkJsBubbleRef.setAttribute('data-badge', unreadMessages.toString());
  }

  removePopupNotifications() {
    const talkJsBubbleRef = document.getElementById('__talkjs_launcher');
    if (!talkJsBubbleRef) return;

    talkJsBubbleRef.removeAttribute('data-badge');
  }

  async destroyTalkJsSession() {
    const session = await this.getTalkJsSession();
    if (session) {
      await session.clearPushRegistrations();
      session.destroy();
      this.currentSessionDeferred = new Deferred<Talk.Session>();
    }
  }
}
