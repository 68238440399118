import { Injectable, NgZone } from '@angular/core';
import { TalkJsService } from '@app/core/services/talkjs/talk-js.service';
import { StoreService } from '@app/store/store.service';
import { TravelDesigner } from '@shared/models/itinerary/travel-designer.model';
import { combineLatest, Observable, of, ReplaySubject, skip, Subscription } from 'rxjs';
import Talk from 'talkjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { SnackBarService } from '@app/core/services/snack-bar/snack-bar.service';
import { MessageNotificationData } from '@shared/mocks/notification-data.model';
import { environment } from '@environment';
import { User } from '@shared/models/user/user.model';
import { AuthDomainService } from './auth-domain.service';
import { ItineraryDomainService } from './itinerary-domain.service';
import { ParticipationSettings } from 'talkjs/all';
import { Status } from '@shared/models/itinerary/status.enum';
import { RouteService } from '@app/api/route.service';
import { Domains, RouteDetails } from '@shared/models/itinerary/route-details.model';
import { UserDomainService } from './user-domain.service';
import { TranslationDomainService } from './translation-domain.service';

@Injectable({
  providedIn: 'root',
})
export class TalkJsDomainService {
  private currentUnreadMessagesSubscription: Subscription | null = null;
  private currentTalkUser: Talk.User | null = null;
  private loadedPopup: Talk.Popup | null = null;
  private shownUnreadMessageIds: string[] = [];
  private newConversations$: Observable<Talk.UnreadConversation[]> | null = null;

  private readonly defaultConversationSettings: Partial<ParticipationSettings> = { access: 'ReadWrite' };
  private readonly defaultTripConversationSettings: Record<Status, Partial<ParticipationSettings>> = {
    [Status.Abandoned]: { ...this.defaultConversationSettings, access: 'Read' },
    [Status.Cancelled]: { ...this.defaultConversationSettings, access: 'Read' },
    [Status.Closed]: { ...this.defaultConversationSettings, access: 'Read' },
    [Status.Booked]: this.defaultConversationSettings,
    [Status.Requested]: this.defaultConversationSettings,
  };

  constructor(
    private ngZone: NgZone,
    private snackBarService: SnackBarService,
    private store: StoreService,
    private talkJsService: TalkJsService,
    private authDomainService: AuthDomainService,
    private itineraryDomain: ItineraryDomainService,
    private userDomainService: UserDomainService,
    private routeService: RouteService,
    private translationDomainService: TranslationDomainService,
  ) {}

  public checkTalkJsInitialization$() {
    const route$ = this.routeService.getRouteDetails$();
    const user$ = this.userDomainService.getCurrentUser$();

    return combineLatest([route$, user$]).pipe(
      tap(([route, user]) => {
        if (!user) this.destroyTalkJsSession();
        if (!this.shouldOpenTalkJsPopup(route)) this.destroyPopup();
      }),
      switchMap(([route, user]) => {
        const isAuth$ = route.hasChatOpen ? this.authDomainService.handleActionWithAuthentication$() : of(!!user);
        return combineLatest([of(route), of(user), isAuth$]);
      }),
      filter(([, user, isAuth]) => !!user && isAuth),
      tap(([, user]) => {
        this.initTalkJs(user);
      }),
      filter(([route]) => this.shouldOpenTalkJsPopup(route)),
      switchMap(([route]) => combineLatest([this.itineraryDomain.updateTrip$(route.hash), of(route)])),
      filter(([trip]) => trip.isMessagingEnabled && !trip.isAmex),
      tap(() => this.createPopup()),
    );
  }

  private shouldOpenTalkJsPopup(route: RouteDetails): boolean {
    const isItineraryRoute = route.domain == Domains.ITINERARY || route.domain == Domains.TRIP;
    return isItineraryRoute && !!route.hash;
  }

  public async initTalkJs(user: User) {
    await this.initTalkJsSession(user);
    this.newConversations$ = await this.subscribeToUnreadConversations();
    this.listenOtherUnreadMessages();
  }

  public destroyTalkJsSession() {
    this.destroyPopup();
    this.talkJsService.destroyTalkJsSession();
    this.currentTalkUser = null;
    this.newConversations$ = null;
  }

  destroyPopup() {
    if (this.loadedPopup) this.loadedPopup.destroy();
    this.loadedPopup = null;
    this.currentUnreadMessagesSubscription?.unsubscribe();
  }

  private async initTalkJsSession(user: User) {
    this.currentTalkUser = await this.createTalkUser(user);
    if (this.currentTalkUser) {
      await this.talkJsService.initTalkJsSession(this.currentTalkUser, user.chatSignature);
    }
  }

  private async createTalkUser(applicationUser: User | TravelDesigner): Promise<Talk.User> {
    const userLanguage = this.translationDomainService.getUserLanguage();
    return await this.talkJsService.createTalkUser(applicationUser, userLanguage);
  }

  public async createPopup() {
    const { trip } = this.store.getItineraryState();
    if (this.loadedPopup && this.loadedPopup.currentConversation?.id.includes(trip.hash)) return;
    if (this.loadedPopup) this.destroyPopup();

    const settings = trip.status ? this.defaultTripConversationSettings[trip.status] : this.defaultConversationSettings;
    const conversation = await this.createConversation(trip.hash, trip.title ?? '', trip.travelDesigner, settings);
    const { routeDetails } = this.store.getItineraryState();
    this.loadedPopup = await this.talkJsService.createPopup();
    if (this.loadedPopup) {
      await this.loadedPopup.select(conversation);
      await this.loadedPopup.mount({ show: routeDetails?.hasChatOpen });
      this.listenCurrentUnreadMessages(trip.hash);
    }
  }

  private async createConversation(
    tripHash: string,
    tripTitle: string,
    staffParticipant: TravelDesigner | null,
    settings: Partial<ParticipationSettings>,
  ): Promise<Talk.ConversationBuilder> {
    const conversationBuilder = await this.talkJsService.getOrCreateConversation(tripHash, tripTitle);

    if (conversationBuilder) {
      this.currentTalkUser && conversationBuilder.setParticipant(this.currentTalkUser, settings);

      if (staffParticipant) {
        const staffTalkUser = await this.createTalkUser(staffParticipant);
        conversationBuilder.setParticipant(staffTalkUser);
      }
    }

    return conversationBuilder;
  }

  private async subscribeToUnreadConversations() {
    const session = await this.talkJsService.getTalkJsSession();
    const newMessagesSubject$ = new ReplaySubject<Talk.UnreadConversation[]>(1);
    session.unreads.onChange(unreadConversations => {
      newMessagesSubject$.next(unreadConversations);
    });
    return newMessagesSubject$.asObservable().pipe(shareReplay({ bufferSize: 1, refCount: false }));
  }

  private listenCurrentUnreadMessages(tripHash: string) {
    if (this.newConversations$) {
      this.currentUnreadMessagesSubscription = this.newConversations$
        .pipe(
          map(conversations => {
            return (
              conversations.find(({ conversation }) => conversation.id.includes(tripHash))?.unreadMessageCount ?? 0
            );
          }),
          tap(unreadCurrentConversationCount => {
            unreadCurrentConversationCount
              ? this.talkJsService.addPopupNotifications(unreadCurrentConversationCount)
              : this.talkJsService.removePopupNotifications();
          }),
        )
        .subscribe();
    }
  }

  private listenOtherUnreadMessages() {
    if (this.newConversations$) {
      const latestMessage$ = this.newConversations$.pipe(
        map(conversations =>
          conversations
            .map(({ lastMessage }) => lastMessage)
            .filter(({ id }) => !this.shownUnreadMessageIds.includes(id))
            .sort((a, b) => b.timestamp - a.timestamp),
        ),
        tap(messages => {
          this.shownUnreadMessageIds = [...this.shownUnreadMessageIds, ...messages.map(({ id }) => id)];
        }),
        skip(1), // skipping past messages to show only real time messages
        filter(messages => !!messages.length),
        map(([message]) => message), // show only latest message
      );

      latestMessage$
        .pipe(
          filter(({ conversation }) => {
            const tripHash = this.store.getItineraryState().routeDetails?.hash;
            return !tripHash || !conversation.id.includes(tripHash);
          }),
          tap(message => {
            // newConversations$ observable emissions come from a callback of an external talkJs lib -> out of ngZone
            // the snackbar should be called within ngZone for being displayed and work correctly
            this.ngZone.run(() => {
              this.snackBarService.openMessageNotification(this.getMessageNotificationData(message));
            });
          }),
        )
        .subscribe();
    }
  }

  private getMessageNotificationData({ sender, conversation, body }: Talk.Message): MessageNotificationData {
    const [firstName, lastName] = sender?.name.split(/\s+/) ?? [];
    return {
      firstName: firstName ?? '',
      lastName: lastName ?? '',
      message: body,
      link: `/itinerary/${environment.production ? conversation.id : conversation.id.replace('staging__', '')}/chat`,
      photoUrl: sender?.photoUrl ?? '',
    };
  }
}
