<ng-container *transloco="let t">
  @if ((loadingState$ | async).isLoading) {
    <div class="backdrop" [ngClass]="{white: color === 'white', black: color === 'black'}">
      <div class="loading-content">
        <ess-loading-spinner [color]="color"></ess-loading-spinner>
        <p class="sans">
          {{ t(messageKey) }}
          <span>{{ t('itinerary.thisRequestCouldTakeAFewSeconds') }} </span>
        </p>
      </div>
    </div>
  }
</ng-container>
