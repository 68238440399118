import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslationDomainService } from '@domain/translation-domain.service';

@Injectable({ providedIn: 'root' })
export class ParamTranslationResolver implements Resolve<string> {
  constructor(
    private translationDomainService: TranslationDomainService,
    private router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const language = route.params.lang;
    if (this.translationDomainService.isLanguageSupported(language)) {
      this.translationDomainService.setActiveLanguage(language);
      return language;
    } else {
      this.router.navigate(['/not-found']);
    }
  }
}
